<template>
    <div class="photo">
        <div class="product_img">
            <img class="" :src="backImg" alt="">
        </div>
        <div class="main">
            <div class="photoText">
                <p>企业相册</p>
                <div class="line"></div>
            </div>
            <div class="photoCon">
                <div class="photoItem" v-for="(item,index) in photoList" :key="index">
                  <a :href="item.href" target="_blank">
                    <img :src="item.image" alt="">
                    <div class="name">{{item.name}}</div>
                  </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            backImg: require('@/assets/images/banner/pthoto.jpg'),
            photoList:[
                {image: require('@/assets/images/photo/photo0.jpg'),name:'海洋光学2022代理商大会暨认证工程师培训',href:'http://www.oceanoptics.cn/news/2718'},
                {image: require('@/assets/images/photo/photo1.png'),name:'海洋光学关于光谱仪解决方案培训',href:'#'},
                {image: require('@/assets/images/photo/photo2.png'),name:'海洋光学关于光谱仪应用产品知识培训',href:'#'},
                {image: require('@/assets/images/photo/photo3.png'),name:'海洋光学苏工关于产品知识培训',href:'#'},
                {image: require('@/assets/images/photo/photo4.png'),name:'海洋光学高经理光谱仪应用的培训',href:'#'},
                {image: require('@/assets/images/photo/photo5.png'),name:'华中科技大学脉冲强磁场中心磨抛机的安装培训现场',href:'#'},
                {image: require('@/assets/images/photo/photo6.png'),name:'华中科技大学材料成型与模具技术国家重点实验室精研磨一体机的安排培训的现场',href:'#'},
            ]
        }
    },
    methods:{}
}
</script>
<style scoped lang="scss">
.photo{
    padding-bottom: 50px;
    background: #f6fbff;
    .photoText{
        padding:40px 0;
        font-size: 30px;
        text-align: center;
        color:#5972f9c2;
        box-sizing: border-box;
        .line{
            width:100px;
            height:3px;
            background: #5972f9c2;
            margin: 15px auto;
        }
    }
    .photoCon{
        padding:20px;
        display: flex;
        flex-wrap: wrap;
        .photoItem{
            flex: 0 0 370px;
            margin-right: 20px;
            margin-bottom: 20px;
            border:1px solid #ccc;
            overflow: hidden;
            
            &:hover{
                box-shadow: 0 0 10px #bcbcbc
            }
            &:hover img{
                transform: scale(1.1);
                
            }
            &:nth-child(3n+3) {
                margin-right: 0;
            }
            img{
                width:100%;
                height:280px;
                background-size: 100%;
                transition: all 0.5s ease;
            }
            .name{
                font-size: 16px;
                text-align: center;
                color: #fff;
                background: #1530bfbf;
                height: 30px;
                padding: 20px 10px;
            }
        }
    }
}
</style>